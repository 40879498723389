<template>
  <div>
      <vx-card class="mb-4">
        <div class="vx-row w-full">
          <div class="vx-col sm:w-4/12 w-4/12">
            <b>{{ $t('questionnaire_corrector.avaliation') }}</b>
          </div>
          <div class="vx-col sm:w-5/12 w-5/12">
            <b>{{ $t('questionnaire_corrector.total_teacher') }}</b>
          </div>
          <div class="vx-col sm:w-3/12 w-3/12">
            <b>{{ $t('questionnaire_corrector.total_answers') }}</b>
          </div>
        </div>

        <div class="vx-row w-full">
          <div class="vx-col sm:w-6/12 w-4/12">{{questionnaire.name}}</div>
          <div class="vx-col sm:w-3/12 w-5/12">{{model.teachers_count}}</div>
          <div class="vx-col sm:w-3/12 w-3/12">{{model.anwers_count}}</div>
        </div>

        <vx-card class="mb-4 mt-4">
          <!--
          <fieldset class="p-2 pt-0 border border-grey">
            <legend class="px-1 font-bol"> {{ $t('filtros') }} </legend>
              <div class="row-start-2 flex items-end gap-2">
                <user-repository
                  class="flex-grow"
                  :userRepository="userRepository"
                  v-model="selectedUsers"/>
                <div>
                  <vs-button :disabled="!validFilters" class="py-auto px-4 mr-2" @click="updateData">{{ $t('filtrar') }}</vs-button>
                  <vs-button class="py-auto px-4" type="border" @click="clearFilters">{{ $t('action.clear') }}</vs-button>
                </div>
              </div>
          </fieldset>
          -->
          <div class="mt-4">
            <Grid :key="key"
              :service="service"
              :hide_searchbar="true"
              order_column="user_name"
              route_name="questionnaire_correctors"
              :route_grid_path="`${id}/dashboard/grid`"
              :column_formats="{
                'status': (val) => $t(`questionnaire_corrector.status.${val}`)
              }"
              :css_formats="{
                status: (val) => {
                  switch (val) {
                  case (null):
                    return 'text-danger'
                  case ('CP'):
                    return 'text-danger'
                  case ('IC'):
                    return 'text-warning'
                  case ('SR'):
                    return 'text-primary'
                  default:
                    return ''
                  }
                }
              }"
            >
            <template v-slot:actions="actions">
                <feather-icon
                  icon="EyeIcon"
                  svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-primary"
                  v-tooltip.auto="{
                    content: $t('action.open_correction'),
                    delay: {
                      show: 500,
                      hide: 500
                    }
                  }"
                  @click="() => openCorrection(actions.row)"/>
              </template>
            </Grid>
          </div>
        </vx-card>

        <!-- row in action-->
        <div class="vx-row mb-2 mt-8">
          <div class="w-full flex gap-2 justify-end">
            <vs-button
              type="border"
              @click="goBack">
              {{ $t('common.back') }}
            </vs-button>
          </div>
        </div>
      </vx-card>
  </div>
</template>

<script>
import QuestionnaireCorrectorService from '@/services/api/QuestionnaireCorrectorService'

export default {
  props: {
    id: {
      default: null,
      type: Number
    }
  },
  data: () => ({
    model:{

    },
    questionnaire:{
      id: null,
      name: ''
    },

    service: null
  }),
  methods: {
    loadData(id) {
      this.$vs.loading()
      const promisses = []

      //Config
      promisses.push(
      this.service.read(id).then(
        response => {
          this.model = response
          this.questionnaire.id = response.questionnaire_id
          this.questionnaire.name = response.questionnaire_name
        }
      ))

      //
      Promise.all(promisses).then(() => {
        this.$vs.loading.close()
        console.log('all load...');
      })
    },
    goBack() {
      this.$router.push('/questionnaire_correctors/dashboard')
    },
    openCorrection(entity) {
      const questionnaireCorrectorId = entity.questionnaire_corrector_id //questionnaire_corrector_id
      const answerId = entity.answer_id //answer_id
      this.$router.push(`/questionnaire_correctors/${questionnaireCorrectorId}/answer/${answerId}/correction`)
    },
  },
  beforeMount() {
    this.service = QuestionnaireCorrectorService.build(this.$vs)
  },
  mounted() {
    if (this.id) {
      this.loadData(this.id)
    }
  },
}
</script>
